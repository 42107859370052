<template>
  <sdPageHeader title="Campaigns">
    <template v-slot:buttons>
      <div class="page-header-actions">
        <sdButton @click="createCampaign('campaigns/select-game')" size="small" type="primary">
          <sdFeatherIcons type="plus" size="14" />
          Create campaign
        </sdButton>
      </div>
    </template>
  </sdPageHeader>
  <Main>
    <sdCards headless>
      <a-row :gutter="15">
        <a-col :md="24">
          <TableWrapper class="table-order table-responsive">
            <a-table
              rowKey="id"
              :dataSource="campaigns"
               @change="handleTableChange"
              :columns="fields"
              :pagination="{ pageSize: perPage, showSizeChanger: true, total: totalData}"
            >
            <template #conversion_rate = "{text: conversion_rate}">
                {{conversion_rate}}%
            </template>
            <template #action="{record}">
              <div class="table-actions">
                <sdButton @click="onEditPress(record)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="edit" size="16" />
                </sdButton>
                <sdButton @click="goToParticipants(`participants/${record.encrypt_id}`, record)" class="btn-icon" type="danger" shape="circle">
                  <sdFeatherIcons type="users" size="16" />
                </sdButton>
                <sdButton @click="openFrontPage(record)" class="btn-icon" type="primary" shape="circle">
                  <sdFeatherIcons type="eye" size="16" />
                </sdButton>
                <sdButton @click="navigateTo(`campaigns/details/${record.encrypt_id}`)" class="btn-icon" type="danger" shape="circle">
                  <sdFeatherIcons type="pie-chart" size="16" />
                </sdButton>
                <sdButton @click="onDuplicatePress(record)" class="btn-icon" type="info" shape="circle">
                  <sdFeatherIcons type="copy" size="16" />
                </sdButton>
              </div>
            </template>
            </a-table>
          </TableWrapper>
        </a-col>
      </a-row>
    </sdCards>
  </Main>
</template>
<script>

import { Main, TableWrapper } from '../../styled';
import { API } from "@/services/api";
import Notification from "@/services/Notification";
//import _ from "lodash";

export default {
  name: "Campaigns",
  components: { Main, TableWrapper },
  computed: {
    authUser: function() {
      return this.$store?.getters?.authUser;
    },
    initial: function() {
      return this.$store?.getters?.initial;
    }
  },
  data() {
    return {
      perPage: 10,
      totalData: 0,
      currentPage: 1,
      sortField: '',
      sortOrder: '',
      fetching: false,
      campaigns: [],
      fields: [
        { title: 'Id',dataIndex: 'id', key: 'id',sorter : true,sort_index:0},
        { title: 'Campaign',dataIndex: 'name',key: 'name',sorter : true,sort_index:1},
        { title: 'Game',dataIndex: 'game_name', key: 'game_name',sorter : true,sort_index:2},
        { title: 'Theme',dataIndex: 'game_name',key: 'game_name',sorter : true,sort_index:3},
        { title: 'Game conversion',dataIndex: 'conversion_rate',key: 'conversion_rate',slots: {
            customRender: 'conversion_rate',
        },sort_index:4},
        { title: 'Total Plays', dataIndex: 'total_subscriber',key: 'total_subscriber',sorter : true,sort_index:5},
        { title: 'Action',dataIndex: 'action', key: 'action', slots: {
          customRender: 'action' },
        },
      ],
    };
  },
  methods: {
    goToParticipants(path) {
      // if (!_.isEmpty(data)) {
      //   this.$store.commit("setNavTitle", {
      //     routeName: "participants",
      //     title: `Participants of campaign ${data.name}`,
      //   });
      // }
      this.navigateTo(path);
      //alert(path);
    },
    navigateTo: function (path = "") {
      if (path) {
        this.$router.push({path});
      }
    },
    onEditPress: function(campaign) {
      const {
        encrypt_id,
        game_slug,
        theme_slug,
        game_id,
        theme_id,
        game_name,
        theme_name,
      } = campaign;
      this.$store.commit("setGame", {
        id: game_id,
        name: game_name,
        slug: game_slug,
      });
      this.$store.commit("setTheme", {
        id: theme_id,
        name: theme_name,
        slug: theme_slug,
      });
      this.navigateTo(`campaigns/setup/${encrypt_id}`);
    },
    async onDuplicatePress(campaign) {
      const {
        encrypt_id,
        game_slug,
        theme_slug,
        game_id,
        theme_id,
        game_name,
        theme_name,
      } = campaign;
      this.$store.commit("setGame", {
        id: game_id,
        name: game_name,
        slug: game_slug,
      });
      this.$store.commit("setTheme", {
        id: theme_id,
        name: theme_name,
        slug: theme_slug,
      });

      const dataRes = await API.duplicateCampaign({campaign_id : encrypt_id});

      const { message, status, encrypt_id: id = "" } = dataRes.data;

      Notification.show(message, {
            type: status ? "success": "error",
            title: status ? "Success": "Error",
      });

      if(status) {
        this.navigateTo(`campaigns/setup/${id}`);
      }
     
    },
    async getCampaigns() {
      try {
        this.fetching = true;
        const params = {
          page: this.currentPage,
          limit: this.perPage,
          sort_field: this.sortField,
          sort_order: this.sortOrder,
        };
        const resData = await API.getCampaigns({params});
        const { data: campaignsData, status } = resData.data;
        if (status) {
          this.campaigns = campaignsData.campaign;
         
          this.totalData = campaignsData.total_rows;
        }
      } catch (error) {
        if (error?.message) {
          //Toast.show(error.message, {type: "error"});
        }
      } finally {
        this.fetching = false;
      }
    },
    clikOnPage(bvEvent, page) {
      bvEvent.preventDefault();
      this.currentPage = page;
      this.getCampaigns();
    },
    openFrontPage(campaign) {
      const { campaign_domain = "", encrypt_id = "", slug = "" } = campaign;
      if (process.env.NODE_ENV == "development") {
        const routeData = this.$router.resolve({path: `/${slug}`});
        window.open(routeData.href, "_blank");
      } else {
        if (!campaign_domain && encrypt_id) {
          window.open(`${process.env.VUE_APP_DOMAIN}${slug}`, "_blank");
        } else {
          window.open(`${campaign_domain}${slug}`, "_blank");
        }
      }
    },
    handleTableChange(page, filters, sorter) {
      if (this.currentPage != page.current) {
        this.currentPage = page.current;
        setTimeout(() => {
          this.getCampaigns();
        }, 300);
      }
      if(typeof sorter.column.sort_index != "undefined" && sorter.order != "" ) {
        this.sortField = sorter.column.sort_index;
        this.sortOrder = "asc";
        if(sorter.order != "" && sorter.order == "descend") {
          this.sortOrder = "desc";
        }
        this.getCampaigns();
      }
    },
    createCampaign(path) {
      if(this.authUser.plan_expired_notice) {
        Notification.show('Upgrade plan to continue campaigns or wait for next month', {type: "error",title: "Error"});
      } else {
        this.navigateTo(path);
      }
    }
  },
  created() {
    this.getCampaigns();
  }
}

</script>
